import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router';
import store from './store';
// Import main.css
import "./assets/css/main.css"

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small'})
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
