import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

let routes = [{
		path: '/',
		component: 'layouts/main',
		redirect: {
			name: 'index'
		},
		children: [{
			component: 'index/index',
			path: '/',
		}, {
			path: '*',
			redirect: {
				name: 'index'
			}
		}]
	}];

// 获取路由信息方法
let getRoutes = function() {
	createRoute(routes)
	return routes
}
// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		let val = removeTrailingIndex(arr[i].component)
		arr[i].name = arr[i].name || getRouteName(val)
		arr[i].path = arr[i].path || getRoutePath(val)
		let componentFun = import(`./views/${arr[i].component}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}

function removeTrailingIndex(str) {
	// 获取最后一个/的索引
	let index = str.lastIndexOf('/')
	let val = str.substring(index + 1, str.length)
	if (val === 'index') {
		return str.substring(index, -1)
	}
	return str
}

function getRouteName(val) {
	return val.replace(/\/_[a-z]+/g, '').replace(/\//g, '_')
}

function getRoutePath(val) {
	return `/${val.replace(/_/g, ':')}`
}

const router = new Router({
	mode: 'history',
	routes: getRoutes()
});

router.beforeEach((to, from, next) => {
	next()
})
export default router;