<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	import {fetchJSONByGet} from '@/apis/index.js'
	export default {
		name: 'App',
		created() {
			fetchJSONByGet('https://api.ipify.org?format=json')
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
			})
		}
	};
</script>

<style>
</style>