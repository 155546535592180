import '@/plugins/axios'
import VueInstance from '@/main.js'
export const fetchJSONByGet = (url, params = {}, options = {}) => axios.get(url, {
	params,
	...options
})
export const fetchJSONByPost = (url, body = {}, options = {}) => axios.post(url, body, options)
export const fetchJSONByDel = (url, options = {}) => axios.delete(url, options)
export const fetchJSONByApis = function() {
	return Promise.all(Array.from(arguments))
}
/**
 * axios拦截器设置及全局加载提示
 * */
const buildHeaders = (config) => {
	const trigger = config.trigger === false ? '0' : '1'
	const token = localStorage.getItem('TOKEN')
	if (token) {
		config.headers['Authorization'] = token
	}
	return {
		...config.headers,
		trigger
	}
}
const modifyPagable = (config) => {
	if (/get/i.test(config.method) && config.params) {
		const {
			current,
			size
		} = config.params
		if (current) {
			config.params['page'] = current
			delete config.params['current']
		}
		delete config.params['sizes']
		delete config.params['total']
	}
	return config
}
const modifyTimeout = (config) => {
	// if (/get/i.test(config.method)) {
	// 	config['timeout'] = 0
	// } else {
	// 	config['timeout'] = 0
	// }
	config['timeout'] = 0
	return config
}
const paramsSerializer = (config) => {
	if (config.params) {
		config.paramsSerializer = (params) => {
			var qs = require('qs');
			// {countryCodes: ['US', 'CN']} >>> countryCodes=US&countryCodes=CN
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	}
}
function logRes(e) {
	if (!e.request.responseURL.includes('/adms_api_v0')) return
	const url = e.request.responseURL.split('/adms_api_v0')[1].split('?')[0]
	try {
		console.log("%c ↑ API Header: " + url, "background: #010101; padding: 4px; color: #ffe300; border-radius: 4px; margin-bottom: 10px; border: 4px solid #c8c8c8", "\n", e.headers)
	} catch (e) {}
	try {
		Object.keys(e.config.params).length > 0 && console.log("%c ↑ API Request: " + url, "background: #93FFD8; padding: 4px; color: #146356; border-radius: 4px; margin-bottom: 10px; border: 4px solid #CFFFDC", "\n", e.config.params)
	} catch (e) {}
	try {
		console.log("%c ↓ API Response: " + url, "background: #CDDEFF; padding: 4px; color: #1C6DD0; border-radius: 4px; margin-bottom: 10px; border: 4px solid #A2D2FF", "\n", e.data)
	} catch(e) {}
}
// 添加请求拦截器
axios.interceptors.request.use((config) => {
	let headers = buildHeaders(config)
	modifyPagable(config)
	modifyTimeout(config)
	paramsSerializer(config)
	return {
		...config,
		headers
	}
}, (error) => {
	// 对请求错误做些什么
	return Promise.reject(error);
});
const decodeErrData = (data) => {
	console.info(data)
	if (typeof data === 'object' && data !== null) {
		const keys = Object.keys(data)
		return keys.filter(key => !/\d+/.test(key) && Array.isArray(data[key])).map(key => {
			return data[key].reduce((lhs, rhs) => {
				lhs.push(`${key} ${rhs}`)
				return lhs
			}, [])
		}).join('\n')
	}
}
// 添加响应拦截器
axios.interceptors.response.use((response) => {
	logRes(response)
	// if (response.data && response.data.success === true) {
	// 	return response.data.data
	// }
	// if (response.data && response.data.success === false && response.data.errorMsg) {
	// 	if (response.data && response.data.errorCode === 'NO_LOGIN') {
	// 		try {
	// 			const {user} = VueInstance.$store.state.user
	// 			console.log(user)
	// 			if (user) {
	// 				Message.error('授权已过期，请重新登入')
	// 				VueInstance.$store.dispatch('logout')
	// 				VueInstance.$router.replace('login')
	// 			}
	// 		} catch {}
	// 	}
	// 	const additionErrMsgs = []
	// 	try {
	// 		/**
	// 		 * 
	// 		 { cause: 'any reason'}
	// 		 { landingPage: '未配置'}
	// 		 */
	// 		if (response.data.data) {
	// 			console.log(response.data.data)
	// 			for (var key in response.data.data) {
	// 				additionErrMsgs.push(key + ' ' + response.data.data[key])
	// 			}
	// 		}
	// 	} catch (e) {
	// 		console.error(e)
	// 	}
	// 	var errMsg = response.data.errorMsg
	// 	if (additionErrMsgs.length > 0) {
	// 		errMsg += '(' + additionErrMsgs.join('/') + ')'
	// 	}
	// 	return Promise.reject(errMsg);
	// }
	return response;
}, (err) => {
	// 对响应错误做点什么
	if (err.response && err.response.data && err.response.data.errorMsg) {
		const errorMsg = decodeErrData(err.response.data.data)
		return Promise.reject(errorMsg || err.response.data.errorMsg)
	}
	try {
		const errorObject=JSON.parse(JSON.stringify(err))
		const errMsg = errorObject.message
		return Promise.reject(errMsg)
	} catch (e) {
		console.error(e);
	}
	return Promise.reject(err)
});


// https://github.com/axios/axios/issues/960
// axios createError 对象解构
/**
 * let errorObject=JSON.parse(JSON.stringify(err));
 * config
 * */
